import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import defaultMuiTheme from './themes/AirGreenland/Default';
import Layout from './hoc/Layout/Layout';
import BookingSearch from './containers/BookingSearch/BookingSearch';
import DangerousGoods from './containers/DangerousGoods/DangerousGoods';
import PurposeOfTravel from './containers/PurposeOfTravel/PurposeOfTravel';
import Checkin from './containers/Checkin/Checkin';
import BoardingPass from './containers/BoardingPass/BoardingPass';
import Summary from './containers/Summary/Summary';
import { LanguageProvider } from './context/language';
import { NavigationProvider } from './context/navigation';
import { BookingProvider } from './context/booking';
import { CheckinProvider } from './context/checkin';
import { SeatMapProvider } from './context/seatMap';
import { BoardingPassProvider } from './context/boardingPass';
import { PurposeOfTravelProvider } from './context/purposeOfTravel';
import { PassengerInfoProvider } from './context/passengerInfo';

import * as routes from './Routes';

const App = () => (
  <MuiThemeProvider theme={defaultMuiTheme}>
    <CssBaseline />
    <LanguageProvider>
      <NavigationProvider>
        <BookingProvider>
          <PassengerInfoProvider>
            <PurposeOfTravelProvider>
              <Layout>
                <Switch>
                  <Route exact path={routes.BOOKING_SEARCH}>
                    <BookingSearch />
                  </Route>
                  <Route exact path={routes.DANGEROUS_GOODS}>
                    <DangerousGoods />
                  </Route>
                  <Route exact path={routes.PURPOSE_OF_TRAVEL}>
                    <PurposeOfTravel />
                  </Route>
                  <Route exact path={routes.CHECK_IN}>
                    <CheckinProvider>
                      <SeatMapProvider>
                        <Checkin />
                      </SeatMapProvider>
                    </CheckinProvider>
                  </Route>
                  <Route exact path={routes.BOARDING_PASS}>
                    <BoardingPassProvider>
                      <BoardingPass />
                    </BoardingPassProvider>
                  </Route>
                  <Route exact path={routes.SUMMARY}>
                    <Summary />
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </Layout>
            </PurposeOfTravelProvider>
          </PassengerInfoProvider>
        </BookingProvider>
      </NavigationProvider>
    </LanguageProvider>
  </MuiThemeProvider>
);

export default App;

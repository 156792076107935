// All routes
export const BOOKING_SEARCH = '/';
export const BOOKING_SEARCH_NO_BOOKING = '/?noBooking=true';
export const DANGEROUS_GOODS = '/dangerous-goods';
export const PURPOSE_OF_TRAVEL = '/purpose-of-travel';
export const SELECT_FLIGHT = '/select-flight';
export const CHECK_IN = '/check-in';
export const SEATMAP = '/seatmap';
export const BOARDING_PASS = '/boarding-pass';
export const SUMMARY = '/summary';
